import './stylesheets/main.scss';
import initialize from './scripts/utils/component-loader';
import initializeHovers from './scripts/core/hover';
import './scripts/utils/jquery-utils';

// copy asset images to dist folder
require.context('./assets/images', true, /^\.\//);

document.addEventListener('DOMContentLoaded', (event) => {

  initialize(event.target);
  initializeHovers(event.target);
});


jQuery(window).on('resize', function(){

  var x = document.getElementById("Iframe").contentWindow;
  var h = x.document.getElementById("component_content").offsetHeight;

  let div;
  div = document.getElementById("Iframe");
  div.style.height = h + 'px';

});